/**
* Created by tudou on 2020/4/12 18:38.
*/
<template>
  <div class="base-content">
    <query-frame
      add-text="充值"
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="门店名称" v-if="isStoreUser">
          <store-list-select v-model="query.shopId"></store-list-select>
        </query-item>
        <query-item label="手机号">
          <a-input v-model="query.mobile" placeholder="手机号"></a-input>
        </query-item>
        <query-item label="充值时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table :columns="columns" :dataSource="dataList">
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
    </query-frame>

    <!-- 收款弹出窗 开始 -->
    <payment-modal v-model="payVisible" :data="actionData" @on-ok="handlePayOk"></payment-modal>
    <!-- 收款弹出窗 结束 -->

    <!-- 充值小票 开始 -->
    <recharge-bill ref="bill"></recharge-bill>
    <!-- 充值小票 结束 -->
  </div>
</template>

<script>
import { feeRechargeList } from '@/api/fee';
import queryMixin from '@/mixin/queryMixin';
import PaymentModal from '@/components/PaymentModal.vue';
import RechargeBill from '@/components/print/RechargeBill.vue';
import IDatePicker from '../../components/IDatePicker.vue';
import { deepClone } from '@/libs/utils';

export default {
  name: 'feeRecharge',
  mixins: [queryMixin],
  components: {
    PaymentModal,
    RechargeBill,
    IDatePicker
  },
  props: {},
  data() {
    return {
      query: {
        storeId: null,
        userCode: null,
        mobile: null,
        duration: []
      },
      actionData: {},
      payVisible: false,

      // 充值状态
      statusMap: new Map([
        [1, '未支付'],
        [2, '充值成功'],
        [3, '已取消'],
      ]),
      columns: [
        {
          title: '序号',
          ellipsis: true,
          width: 80,
          customRender(text, item, index) {
            return index + 1;
          }
        },
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '订单号',
          dataIndex: 'orderId',
          key: 'orderId',
          ellipsis: true,
          width: 200,
        },
        {
          title: '订单来源',
          dataIndex: 'orderSourceName',
          key: 'orderSourceName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '会员名称',
          dataIndex: 'memberName',
          key: 'memberName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true,
          width: 130,
        },
        {
          title: '订单名称',
          dataIndex: 'goodsName',
          key: 'goodsName',
          width: 200,
        },
        {
          title: '应付金额',
          dataIndex: 'amountPay',
          key: 'amountPay',
          width: 120,
        },
        {
          title: '支付方式',
          dataIndex: 'payMethodName',
          key: 'payMethodName',
          ellipsis: true,
          width: 160,
        },
        {
          title: '支付备注',
          dataIndex: 'discountWayRamarks',
          key: 'discountWayRamarks',
          ellipsis: true,
          width: 160,
        },
        {
          title: '充值时间',
          dataIndex: 'orderTime',
          key: 'orderTime',
          ellipsis: true,
          width: 180,
        },
        {
          title: '状态',
          dataIndex: 'orderStatusName',
          key: 'orderStatusName',
          ellipsis: true,
          width: 120,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 200,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row) => {
            return (
              <section>
                <a-button type="link" size="small" v-show={row.orderStatus > 1} onClick={ () => this.handlePrint(row) }>打印</a-button>
                <a-divider type="vertical" v-show={row.orderStatus === 1}></a-divider>
                <a-button type="link" size="small" v-show={row.orderStatus === 1} onClick={ () => this.handlePay(row) }>付款</a-button>
              </section>
            );
          }
        }
      ],
      dataList: []
    };
  },
  computed: {
    isStoreUser() {
      const userInfo = this.$store.getters.getUserInfo;
      return userInfo.userDTO.shopId === 0;
    }
  },
  watch: {},
  created() {
    this.handleQuery();
  },
  mounted() {
  },
  destroyed() {
  },
  methods: {
    // 获取会员列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }
      this.dataList = [];

      feeRechargeList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.orderId;
        });
        this.dataList = list;
      });
    },

    // 充值
    handleAdd() {
      this.$router.push({
        name: 'feeRechargeAdd'
      });
    },
    // 收款
    handlePay(row) {
      this.actionData = deepClone(row);
      this.payVisible = true;
    },

    // 支付成功
    handlePayOk() {
      this.getData();
    },

    // 打印小票
    handlePrint(row) {
      this.$nextTick(() => {
        this.$refs.bill.handlePrintBill(row.orderId);
      });
    },
  }
};
</script>

<style lang="less" scoped>

</style>
